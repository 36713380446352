export class Feature {
    id: string;
    label: string;
    description: string;
    description_enable: string;
    description_disable: string;
    detail_url: string;
    image_url?: string;
    video_url?: string;
    availableIn: 'labs' | 'free' | 'protected';
    tags: string[];
    spotlight: boolean;
    status: {
        enabled: boolean;
    }

    constructor(doc) {
        this.id = doc.id
        this.label = doc.label;
        this.description = doc.description;
        this.description_enable = doc.description_enable;
        this.description_disable = doc.description_disable;
        this.detail_url = doc.detail_url;
        this.image_url = doc.image_url;
        this.video_url = doc.video_url;
        this.availableIn = doc.available_in;
        this.spotlight = doc.spotlight;
        this.tags = doc.tags;
        this.status = {
            enabled: doc.status.enabled
        };
    }
}
