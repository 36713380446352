@if ($has_permission()) {
<div>
  <h2>AI Form Filler</h2>
</div>
<div>
  @if ($recognitionState().alertMessage) {
    <div>
      <p>{{ $recognitionState().alertMessage }}</p>
    </div>
  }
  @if ($recognitionState().state !== 'unavailable') {
    <section>
      <button mat-button *ngIf="$recognitionState().buttonLabel" [disabled]="$isButtonDisabled()" type="button" id="main-btn" (click)="handleMainBtnClick()">{{ $recognitionState().buttonLabel }}</button>
      <button mat-button *ngIf="$recognitionState().state === 'listening'" type="button" id="discard-btn" (click)="handleDiscardBtnClick()">Discard</button>
    </section>
  }
  @if ($bannerVisible()) {
    <div style="position: fixed; top: 0; left: 0; width: 100%; color: #893838; z-index: 1000; background-color: #ffebee; padding: 8px;">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <span>{{ $recognitionState().alertMessage }}</span>
        @if ($recognitionState().state === 'listening') {
          <span>
            Audio recording will stop in <span>{{ $recordingTimeLeft() | date:'mm:ss' }}</span>.
            <button mat-button type="button" id="extend-btn" (click)="handleContinueBtnClick()">Extend</button>
          </span>
        }
        <div class="button-container">
        @if ($recognitionState().state === 'stopped') {
          <button mat-button type="button" id="continue-btn" (click)="handleContinueBtnClick()">Continue Recording</button>
          <button mat-button color="primary" type="button" id="apply-btn" (click)="handleApplyBtnClick()">Apply</button>
          <button mat-button color="warn" type="button" id="discard-btn" (click)="handleDiscardBtnClick()">Discard</button>
        }
        </div>
      </div>
    </div>
  }
</div>
}