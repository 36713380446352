(function() {
  'use strict';

  var bulkActionsStates = {
    new: {
      id: 'new',
      name: 'New',
      labelStyle: 'info',
      borderStyle: 'info'
    },
    processing: {
      id: 'processing',
      name: 'Processing',
      labelStyle: 'warning',
      borderStyle: 'pending'
    },
    success: {
      id: 'success',
      name: 'Completed',
      labelStyle: 'success',
      borderStyle: 'complete'
    },
    failure: {
      id: 'failure',
      name: 'Failed',
      labelStyle: 'danger',
      borderStyle: 'danger'
    },
    aborted: {
      id: 'aborted',
      name: 'Aborted',
      labelStyle: 'danger',
      borderStyle: 'danger'
    }
  };

  var bulkActionsDefinitions = [
    {
      id: 'import_file',
      name: 'Bulk import users or events via CSV',
      securityPolicy: {
        roles: [],
        permissions: ['importer.users', 'importer.events']
      }
    },
    {
      id: 'bulk_enable_users',
      name: 'Enable users in Bulk',
      helpTextHtml: 'To enable users in bulk, you\'ll need to fill out a CSV file including a ' +
        'column: <em>"profile_id"</em>.<br/>' +
        'The <em>"profile_id"</em> column should contain the ID of the user you want to enable.' +
        '<br/><br/>' +
        'You can find the user ID by navigating to the user\'s summary page and looking at the ' +
        'URL. It should be in this format: <code>/#/summary/{PROFILE ID}</code>.<br/><br/>\nEach ' +
        'row in the CSV file represents a single user to be enabled.\n<br><br>',
      CSVTemplate: {
        headers: 'profile_id',
        name: 'enable_users.csv'
      },
      formElements: [{ type: 'csv', label: 'Select the CSV to enable users.' }],
      successNotification: 'Task to enable users added, please wait...',
      actionButtonTitle: 'Enable users',
      securityPolicy: {
        roles: [],
        permissions: ['users.bulk.enableDisable']
      }
    },
    {
      id: 'bulk_disable_users',
      name: 'Disable users in Bulk',
      helpTextHtml: 'To disable users in bulk, you\'ll need to fill out a CSV file including a ' +
        'column: <em>"profile_id"</em>.<br/>' +
        'The <em>"profile_id"</em> column should contain the ID of the user you want to disable.' +
        '<br/><br/>' +
        'You can find the user ID by navigating to the user\'s summary page and looking at the ' +
        'URL. It should be in this format: <code>/#/summary/{PROFILE ID}</code>.<br/><br/>\nEach ' +
        'row in the CSV file represents a single user to be disabled.\n<br><br>',
      CSVTemplate: {
        headers: 'profile_id',
        name: 'disable_users.csv'
      },
      formElements: [{ type: 'csv', label: 'Select the CSV to disable users.' }],
      successNotification: 'Task to disable users added, please wait...',
      actionButtonTitle: 'Disable users',
      securityPolicy: {
        roles: [],
        permissions: ['users.bulk.enableDisable']
      }
    },
    {
      id: 'bulk_merge_users',
      name: 'Merge users in Bulk',
      helpTextHtml: '' +
        'To merge users, you\'ll need to fill out a CSV file with two columns: ' +
        '<em>"src_profile_id"</em> and <em>"dest_profile_id"</em>.<br/>' +
        'The <em>"src_profile_id"</em> column should contain the ID of the user whose data you ' +
        'want to merge, and the <em>"dest_profile_id"</em> column should contain the ID of the ' +
        'user whose data you want to merge onto.<br/><br/>' +
        'You can find the user ID by navigating to the user\'s summary page and ' +
        'looking at the URL. It should be in this format: <code>/#/summary/{USER ID}</code>.' +
        '<br/><br/>\nEach row in the CSV file represents a single merge operation.\n<br><br>',
      CSVTemplate: {
        headers: 'src_profile_id,dest_profile_id',
        name: 'merge_users.csv'
      },
      formElements: [{ type: 'csv', label: 'Select the CSV to merge users.' }],
      successNotification: 'Task to merge users added, please wait...',
      actionButtonTitle: 'Merge users',
      securityPolicy: {
        roles: ['system:admin'],
        permissions: []
      }
    },
    {
      id: 'bulk_merge_msfs',
      name: 'Merge MSFs events in Bulk',
      helpTextHtml: 'To merge MSF events, you\'ll need to fill out a CSV file with two' +
        ' columns: <em>"src_event_id"</em> and <em>"dest_event_id"</em>.<br/>' +
        'The <em>"src_event_id"</em> column should contain the ID of the event(s) you want to ' +
        'merge, and the <em>"dest_event_id"</em> column should contain the ID of the event you ' +
        'want to merge the source event(s) onto.<br/><br/>' +
        'You can find the event ID by navigating to the event\'s view page and looking at the' +
        ' URL. It should be in this format: <code>/#/events/view/{EVENT ID}</code>.<br/><br/>' +
        'Each row in the CSV file represents a source event to be merged. Before performing ' +
        'the merge operation, the system will group all rows with the same ' +
        '<em>"dest_event_id"</em> and merge the corresponding source events as a single ' +
        'operation. When merging, the first section of the destination event will be retained,' +
        ' while the first section of each source event will be removed. Finally, the MSF' +
        ' responses will be added to the destination event in the order they are listed in the ' +
        'CSV file.<br><br>',
      CSVTemplate: {
        headers: 'src_event_id,dest_event_id',
        name: 'merge_msfs_events.csv'
      },
      formElements: [{ type: 'csv', label: 'Select the CSV to merge MSFs events.' }],
      successNotification: 'Task to merge msfs added, please wait...',
      actionButtonTitle: 'Merge MSFs',
      securityPolicy: {
        roles: ['system:admin'],
        permissions: []
      }
    }
    // bulk_upgrade_goalsets: {
    //   id: 'bulk_upgrade_goalsets',
    //   name: 'Upgrade goalsets to the latest published version',
    //   helpTextHtml: '',
    //   CSVTemplate: {
    //     headers: 'email',
    //     name: 'filter_by_user_email.csv'
    //   },
    //   formElements: [
    //     {
    //       type: 'formly-form',
    //       fields: [
    //         { id: 'eventTypeId', type: 'string', label: 'Event type id', required: true },
    //         { id: 'relink', type: 'boolean', label: 'Relink Existing events', required: false },
    //         {
    //           id: 'updateAllVersionsExceptPublished',
    //           type: 'boolean',
    //           label: 'Update all versions from same group except published',
    //           required: false
    //         },
    //         {
    //           id: 'updateClosedGoalsets',
    //           type: 'boolean',
    //           label: 'Update closed goalsets',
    //           required: false
    //         }
    //       ]
    //     },
    //     { type: 'csv', label: 'Select the CSV to filter by users\' email.' }
    //   ],
    //   successNotification: 'Task to upgrade goalsets, please wait...',
    //   actionButtonTitle: 'Upgrade goalsets',
    //   securityPolicy: {
    //     roles: ['system:superadmin'],
    //     permissions: []
    //   }
    // }
  ];

  var taskNames = bulkActionsDefinitions.reduce((obj, action) => {
    obj[action.id] = action.name;
    return obj;
  }, {});

  var definitionById = bulkActionsDefinitions.reduce((obj, action) => {
    obj[action.id] = action;
    return obj;
  }, {});

  angular.module('component.bulkActions')
    .constant('TASK_NAMES', taskNames)
    .constant('BULK_ACTIONS_STATES', bulkActionsStates)
    .constant('BULK_ACTIONS_DEFINITIONS', bulkActionsDefinitions)
    .constant('BULK_ACTIONS_DEFINITIONS_BY_ID', definitionById);
})();
