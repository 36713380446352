<section class="wrapper">
    <div class="row">
        <div class="col-xs-8">
            <h2>
                {{ feature().label }}
            </h2>
            <div>
                @for (tag of feature().tags; track tag) {
                    <span class="tag tag-no-count">
                        <span class="tag-name">{{ tag }}</span>
                    </span>
                }
            </div>
        </div>
        <div class="col-xs-4 text-right">
            @if (feature().status.enabled) {
                <span class="label label-success">Enabled</span>
            } @else {
                <span class="label label-danger">Disabled</span>
            }
        </div>
        <div [ngClass]="{'col-xs-8': feature().spotlight, 'col-xs-12': !feature().spotlight}">
            <p [innerHtml]="feature().description"></p>
            <p>
                <a class="btn btn-outline btn-info btn-sm" target="_blank" [href]="feature().detail_url"
                  attr.aria-label="View details about {{ feature().label }}">View details</a>
            </p>
            <hr />
            @if (canManageFeatures()) {
                <p>You can enable or disable this feature:</p>
                @if (feature().status.enabled) {
                    <button type="button" class="btn btn-danger" (click)="disableFeature()"
                      attr.aria-label="Disable {{ feature().label }}">Disable</button>
                    <p><button class="btn-link" type="button" (click)="openDialog()">What happens if I disable this feature?</button></p>
                } @else {
                    <button type="button" class="btn btn-success" (click)="enableFeature()"
                      attr.aria-label="Enable {{ feature().label }}">Enable</button>
                    <p><button class="btn-link" type="button" (click)="openDialog()">What happens if I enable this feature?</button></p>
                }
            }
        </div>
        @if (feature().spotlight) {
        <div class="col-xs-4">
            @if (feature().image_url) {
                <p>
                    <img [src]="feature().image_url" class="img-responsive" />
                </p>
            }
            @if (feature().video_url) {
                <p>
                    <video [src]="feature().video_url" controls class="img-responsive"></video>
                </p>
            }
        </div>
        }
    </div>
</section>
