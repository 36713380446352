import { Injectable, inject, signal } from '@angular/core';
import { Api } from '@fry/core/api';

const RISRAI_API_FILL_FORM = '/ai/ai_form_filler';

export interface RecognitionState {
    alertMessage: string;
    buttonLabel: string;
    state: 'error' | 'success' | 'idle' | 'listening' | 'processing' | 'unavailable' | 'stopped';
}

const RECORDING_TIMEOUT = 5 * 60 * 1000; // 5 minutes

@Injectable()
export class AiFormFillerService {
    private formData = signal<any>({});
    private api = inject(Api);
    $formData = this.formData.asReadonly();

    private recognition: any;
    public $masterTranscript = signal<string>('');

    private _recognitionState = signal<RecognitionState>({
        alertMessage: 'Record your review with your student and let our AI populate a first draft of the form on the left for you.',
        buttonLabel: 'Start Recording',
        state: 'idle'
    });
    $recognitionState = this._recognitionState.asReadonly();

    private recordingTimeout: any;
    private recordingWarningTimeout: any;
    private recordingTimeLeftInterval: any;
    public $recordingTimeLeft = signal<number>(0);

    private formSpec: any;
    constructor() { }

    setFormSpec(formSpec: any) {
        this.formSpec = formSpec;
    }

    async send(transcript: string): Promise<any> {
        console.log(transcript)
        const formData = {
            form_schema: this.formSpec,
            transcript: transcript
        };
        const response = await this.api.post<any>(RISRAI_API_FILL_FORM, formData);
        if (response.state !== 'ok') {
            throw new Error(response.error);
        }
        return response.data;
    }

    updateFormData(data: any) {
        this.formData.set(data);
    }

    initSpeachRecognition() {
        const SpeechRecognition = window['SpeechRecognition'] || window['webkitSpeechRecognition'];
        if (!SpeechRecognition) {
            this._recognitionState.set({
                alertMessage: "Sorry, your browser does not support Speech Recognition. Try using Chrome or Safari.",
                buttonLabel: '',
                state: 'unavailable'
            });
            return;
        }
        this.recognition = new SpeechRecognition();
        this.$masterTranscript.set('');

        this.recognition.continuous = true;
        this.recognition.interimResults = false;
        this.recognition.lang = "en-UK";

        this.recognition.onresult = (event: any) => {
            console.info("Speech recognition result");
            let currentTranscript = "";
            for (let i = event.resultIndex; i < event.results.length; i++) {
                currentTranscript = event.results[i][0].transcript;
            }
            this.$masterTranscript.update(val => val + ' ' + currentTranscript);
        };
        this.recognition.onerror = (event: any) => {
            console.error("Speech recognition error: " + event.error, event.message);
            if (event.error === 'no-speech') {
                setTimeout(() => {
                    this.restartSpeechRecognition();
                });
            } else if (event.error === 'network') {
                this._recognitionState.set({
                    alertMessage: "Sorry, your browser does not support Speech Recognition. Try using Chrome or Safari.",
                    buttonLabel: '',
                    state: 'unavailable'
                });
            } else if (event.error === 'not-allowed') {
                this._recognitionState.set({
                    alertMessage: "Sorry, your microphone is not allowed. Please enable it in your browser settings.",
                    buttonLabel: 'Retry',
                    state: 'error'
                });
            } else {
                this._recognitionState.set({
                    alertMessage: "Speech recognition error: " + event.error,
                    buttonLabel: '',
                    state: 'error'
                });
            }
        };
    }

    startSpeechRecognition() {
        if (this.recognition) {
            this.recognition.start();
            this._recognitionState.set({
                alertMessage: "The audio is being recorded.",
                buttonLabel: 'Stop Recording',
                state: 'listening'
            });
            this.setupRecordingTimeouts();
        }
    }

    restartSpeechRecognition() {
        if (this.recognition) {
            this.recognition.start();
        }
    }

    stopSpeechRecognition() {
        if (this.recognition) {
            this.recognition.stop();
            this._recognitionState.set({
                alertMessage: "The audio has been stopped. If you want to keep recording, click the 'Continue Recording' button.",
                buttonLabel: 'Start Recording',
                state: 'stopped'
            });
            this.clearTimeouts();
        }
    }

    private setupRecordingTimeouts() {
        if (this.recognition && this._recognitionState().state !== 'listening') {
            throw new Error('Not in listening state');
        }

        console.log('setupRecordingTimeouts');
        this.clearTimeouts();
        this._recognitionState.set({
            alertMessage: "The audio is being recorded.",
            buttonLabel: 'Stop Recording',
            state: 'listening'
        });
        this.$recordingTimeLeft.set(RECORDING_TIMEOUT);
        this.recordingTimeLeftInterval = setInterval(() => {
            const newTimeLeft = this.$recordingTimeLeft() - 1000;
            if (newTimeLeft >= 0) {
                this.$recordingTimeLeft.set(newTimeLeft);
            } else {
                clearInterval(this.recordingTimeLeftInterval);
            }
        }, 1000);

        this.recordingTimeout = setTimeout(() => {
            clearInterval(this.recordingTimeLeftInterval);
            this.stopSpeechRecognition();
        }, RECORDING_TIMEOUT);
        this.recordingWarningTimeout = setTimeout(() => {
            const remaining = Math.round((RECORDING_TIMEOUT - this.recordingTimeout) / 1000); 
            this._recognitionState.set({
                alertMessage: `Recording stops in ${remaining} seconds...`,
                buttonLabel: 'Stop Recording',
                state: 'listening'
            });
        }, RECORDING_TIMEOUT * 0.8);
    }

    extendRecording() {
        this.setupRecordingTimeouts();
    }

    private clearTimeouts() {
        console.log('clearTimeouts');
        if (this.recordingTimeLeftInterval) {
            clearInterval(this.recordingTimeLeftInterval);
        }
        if (this.recordingTimeout) {
            clearTimeout(this.recordingTimeout);
        }
        if (this.recordingWarningTimeout) {
            clearTimeout(this.recordingWarningTimeout);
        }
    }

    discardTranscript() {
        this.$masterTranscript.set('');
        this._recognitionState.set({
            alertMessage: "Discarded.",
            buttonLabel: 'Start Recording',
            state: 'idle'
        });                         
    }

    async processTranscript() {
        this._recognitionState.set({
            alertMessage: "Processing...",
            buttonLabel: 'Processing...',
            state: 'processing'
        });
        this._processTranscript(this.$masterTranscript());
    }

    private async _processTranscript(transcript: string): Promise<any> {
        if (transcript.trim() === '') {
            this._recognitionState.set({
                alertMessage: "No data to process.",
                buttonLabel: 'Start Recording again',
                state: 'success'
            });
            return;
        }

        try {
            const data = await this.send(transcript);
            this._recognitionState.set({
                alertMessage: "Success!",
                buttonLabel: 'Start Recording',
                state: 'success'
            });
            this.updateFormData(data);
        } catch (error) {
            this._recognitionState.set({
                alertMessage: "Failed to process the form.",
                buttonLabel: 'Start Recording',
                state: 'error'
            });
        }
    }
}
